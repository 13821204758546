<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/vehicle_categories">Kategorien</router-link></li>
              <li>Kategorie hinzufügen</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/vehicle_categories" class="button button-grey button-small">Alle Kategorien ›</router-link></p>
            </div>
          </header>

          <div class="admin-body">

            <form @submit="create_vehicle_category" class="form" method="post">

              <div class="form-wrap">
                <label for="name">Name</label>
                <input v-model="vehicle_category.name" class="form-input" type="text" name="name" id="name" placeholder="Name">
              </div>

              <div class="form-wrap">
                <label for="tagline">Tagline</label>
                <input v-model="vehicle_category.tagline" class="form-input" type="text" name="tagline" id="tagline" placeholder="Tagline">
              </div>

              <div class="row row-gutter-20">
                <div class="col-6">
                  <div class="form-wrap">
                    <label for="seats">Sitze</label>
                    <input v-model="vehicle_category.seats" class="form-input" type="text" name="seats" id="seats" placeholder="2">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-wrap">
                    <label for="doors">Türen</label>
                    <input v-model="vehicle_category.doors" class="form-input" type="text" name="doors" id="doors" placeholder="5">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-wrap">
                    <label for="luggage">Gepäck</label>
                    <input v-model="vehicle_category.luggage" class="form-input" type="text" name="luggage" id="luggage" placeholder="20">
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-wrap select-wrap">
                    <label for="drive">Getriebe</label>
                    <select v-model="vehicle_category.drive" class="select-wrap">
                      <option value="manual">Schalter</option>
                      <option value="automatic">Automatik</option>
                      <option value="not_any">Keine</option>
                    </select>
                  </div>
                </div>
              </div>

              <p>
                <input type="submit" value="Marke erstellen ›" class="button button-orange button-small">
              </p>

            </form>

          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      locations: [],
      vehicle_category: {},
    }
  },
  methods: {
    get_data() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })
    },
    create_vehicle_category: function (e) {
      e.preventDefault();

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories', this.vehicle_category, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.$router.push('/admin/vehicle_categories');
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    }
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

</style>
